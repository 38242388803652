
const fromClass = 'opacity-10';
const toClass = 'opacity-75';
const intervalDelay = 2500;

const setRandom = () => highlighted = images[Math.floor(Math.random() * images.length)]
const dehighlight = () => {
  highlighted.classList.add(fromClass);
  highlighted.classList.remove(toClass);
};
const highlight = () => {
  highlighted.classList.add(toClass);
  highlighted.classList.remove(fromClass);
}

const images = [...document.querySelectorAll('[data-bg-img]')];
if (images.length === 0) {
  return;
}
let highlighted;
setRandom();
highlight();

window.setInterval(() => {
  dehighlight();
  setRandom();
  highlight();
}, intervalDelay);
